import { FC, useState } from 'react';
import { Quiz, QuizAnswer } from './quiz';

import './app.less';

export const App: FC = () => {
  const [started, setStarted] = useState(false);
  const [answers, setAnswers] = useState<QuizAnswer[]>();

  if (!started) {
    return (
      <div className="quiz-intro">
        <button onClick={() => setStarted(true)}>začít kvíz</button>
      </div>
    );
  } else if (!answers) {
    return (
      <Quiz onComplete={setAnswers} />
    );
  } else if (answers.every((a) => a.ok)) {
    return (
      <div className="quiz-ok">
        <h1>slay</h1>
      </div>
    );
  } else {
    return (
      <div className="quiz-fail">
        <p>to není ten nejlepší výsledek co jsme tu viděli</p>
        <button onClick={() => location.reload()}>try again</button>
      </div>
    );
  }
};
