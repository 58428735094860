import { FC, useState } from 'react';
import { definitions } from './definitions';
import { Question } from './question';

export type QuizAnswer = {
  id: string;
  ok: boolean;
};

export type QuizProps = {
  onComplete: (answers: QuizAnswer[]) => void;
};

export const Quiz: FC<QuizProps> = ({ onComplete }) => {
  const [answers, setAnswers] = useState<QuizAnswer[]>([]);
  const [current, setCurrent] = useState(0);

  return (
    <Question
      def={definitions[current]}
      answer={answers[current]?.ok}
      onAnswer={(ok) => setAnswers([...answers, { id: definitions[current].id, ok }])}
      onNext={() => current + 1 < definitions.length ? setCurrent(current + 1) : onComplete(answers)} />
  );
};
