import { FC } from 'react';
import { Definition } from './definitions';

import './question.less';

export type QuestionProps = {
  def: Definition;
  answer?: boolean;
  onAnswer: (ok: boolean) => void;
  onNext: () => void;
};

export const Question: FC<QuestionProps> = ({ def, answer, onAnswer, onNext }) => {
  const url = answer === undefined ? def.question : answer ? def.ok : def.fail;

  if (answer === undefined) {
    const preload = {
      ok: new Image(),
      fail: new Image(),
    };

    preload.ok.src = def.ok.href;
    preload.fail.src = def.fail.href;
  }

  return (
    <div className="question" style={{ backgroundImage: `url("${url.href}")` }}>
      <h2>Kam patří <strong>{def.label}?</strong></h2>
      {answer === undefined ? (
        <div className="question-buttons">
          <button className="question-trash" title="Vyhodit" onClick={() => onAnswer(def.answer === 'trash')} />
          <button className="question-return" title="Vrátit" onClick={() => onAnswer(def.answer === 'return')} />
        </div>
      ) : (
        <>
          <h3 className="question-answer">{answer ? 'správně!' : 'špatně!'}</h3>
          <button className="question-next" onClick={onNext}>další</button>
        </>
      )}
    </div>
  );
};
