export type Definition = {
  id: string;
  label: string;
  answer: 'trash' | 'return';
  question: URL;
  ok: URL;
  fail: URL;
  order: number;
};

export const definitions: Definition[] = [
  {
    id: 'aquila',
    label: 'Aquila neperlivá',
    answer: 'return',
    question: new URL('../images/q/aquila-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/aquila-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/aquila-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'bakalar',
    label: 'Bakalář nealko',
    answer: 'return',
    question: new URL('../images/q/bakalar-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/bakalar-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/bakalar-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'bata',
    label: 'Matchbata / Icebata / Rooibata',
    answer: 'trash',
    question: new URL('../images/q/bata-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/bata-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/bata-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'cczero',
    label: 'CocaCola Zero',
    answer: 'return',
    question: new URL('../images/q/cczero-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/cczero-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/cczero-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'clubmate',
    label: 'Club Mate',
    answer: 'return',
    question: new URL('../images/q/clubmate-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/clubmate-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/clubmate-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'cocacola',
    label: 'CocaCola',
    answer: 'return',
    question: new URL('../images/q/cocacola-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/cocacola-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/cocacola-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'fevertree',
    label: 'Fever Tree',
    answer: 'trash',
    question: new URL('../images/q/fevertree-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/fevertree-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/fevertree-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'gooseberry',
    label: 'OnLemon',
    answer: 'trash',
    question: new URL('../images/q/gooseberry-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/gooseberry-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/gooseberry-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'guiness',
    label: 'Guiness',
    answer: 'trash',
    question: new URL('../images/q/guiness-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/guiness-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/guiness-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'johannes',
    label: 'Johannes',
    answer: 'trash',
    question: new URL('../images/q/johannes-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/johannes-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/johannes-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'malina',
    label: 'Koli Malina',
    answer: 'return',
    question: new URL('../images/q/malina-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/malina-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/malina-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'mattoni',
    label: 'Mattoni',
    answer: 'return',
    question: new URL('../images/q/mattoni-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/mattoni-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/mattoni-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'maiselsweisse',
    label: 'Maisel\'s Weisse',
    answer: 'return',
    question: new URL('../images/q/maiselsweisse-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/maiselsweisse-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/maiselsweisse-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
  {
    id: 'pilsner',
    label: 'Plzeň',
    answer: 'return',
    question: new URL('../images/q/pilsner-question.jpeg?as=jpeg', import.meta.url),
    ok: new URL('../images/q/pilsner-ok.jpeg?as=jpeg', import.meta.url),
    fail: new URL('../images/q/pilsner-fail.jpeg?as=jpeg', import.meta.url),
    order: Math.random(),
  },
];

definitions.sort((a, b) => a.order - b.order);
